export const IdentifyUser = () => {
  window.analytics.identify('f4ca124298', {
    name: 'Michael Brown',
    email: 'mbrown@example.com',
  });
};

export const genAnonId = () => {
  return window.analytics.user().anonymousId();
};
