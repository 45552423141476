import React from 'react';
import {CheckIcon, XIcon} from '@heroicons/react/solid';
import {useTranslation} from 'react-i18next';

const ComparisonTable = () => {
  const {t} = useTranslation();

  return (
    <div className="bg-gray-100">
      <div className="divider-container">
        <div className="arrow-divider" />
      </div>
      <div className="max-w-7xl mx-auto my-8 sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-orange sm:text-4xl">
            {`${t('gml10.comparisonTable.title1')} `}
            <span className="text-gray-500">
              {t('gml10.comparisonTable.title2')}
            </span>
          </h2>
        </div>
        <div className="flex flex-nowrap px-2 md:px-6 lg:px-8 mt-5 py-10">
          <div className=" w-1/4 text-gray-500     flex-col text-center leading-none  flex items-center justify-center text-lg font-semibold ">
            <div className="h-20 w-full  text-gray-500  text-center leading-none  flex items-center justify-center text-lg font-semibold " />
            <div className="h-20 w-full  leading-none	 text-gray-500  border-b  border-t border-l border-gray-300 flex-none  flex items-center  md:p-4  text-xs p-1 md:text-lg  text-left font-normal ">
              {t('gml10.comparisonTable.rowHeader1')}
            </div>
            <div className="h-20 w-full   leading-none	 text-gray-500  border-b   border-l border-gray-300 flex-none  flex items-center  text-xs md:p-4  p-1 md:text-lg text-left font-normal ">
              {t('gml10.comparisonTable.rowHeader2')}
            </div>
            <div className="h-20 w-full  leading-none	 text-gray-500  border-b   border-l border-gray-300 flex-none  flex items-center   text-xs p-1 md:p-4 md:text-lg  text-left font-normal ">
              {t('gml10.comparisonTable.rowHeader3')}
            </div>
          </div>
          <div className=" w-1/4 text-gray-500  leading-none	  border border-orange  bg-orange-100 flex-col text-center leading-none  flex items-center justify-center text-lg font-semibold ">
            <div className="h-20 w-full  text-orange text-center leading-none  flex items-center justify-center  text-xs p-1 md:text-lg font-semibold ">
              {t('gml10.comparisonTable.colHeader1')}
            </div>
            <div className="h-20 w-full  text-gray-500  border-b  border-t border-gray-300 flex-none  flex items-center justify-center text-lg font-semibold ">
              <CheckIcon className={` w-8 h-8 text-orange`} />
            </div>
            <div className="h-20 w-full text-gray-500  border-b border-gray-300  flex-none  flex items-center justify-center text-lg font-semibold ">
              <CheckIcon className={` w-8 h-8 text-orange`} />
            </div>
            <div className="h-20 w-full  text-gray-500   border-b border-gray-300  flex-none  flex items-center justify-center  text-xs p-1 md:text-lg font-normal ">
              {t('gml10.comparisonTable.valC1R3')}
            </div>
          </div>
          <div className=" w-1/4 text-gray-500   border-t border-b border-gray-300 flex-col text-center leading-none  flex items-center justify-center text-lg font-semibold ">
            <div className="h-20 w-full  leading-none	 text-gray-500  text-center leading-none  flex items-center justify-center text-xs p-1 md:text-lg font-semibold ">
              {t('gml10.comparisonTable.colHeader2')}
            </div>
            <div className="h-20 w-full  text-gray-500  border-b  border-t border-gray-300 flex-none  flex items-center justify-center text-lg font-semibold ">
              <XIcon className={` w-8 h-8 text-gray-500`} />
            </div>
            <div className="h-20 w-full text-gray-500 leading-none	  border-b border-gray-300  flex-none  flex items-center justify-center  text-xs p-1 md:text-lg font-normal ">
              {t('gml10.comparisonTable.valC2R2')}
            </div>
            <div className="h-20 w-full  text-gray-500    border-gray-300  flex-none  flex items-center justify-center  text-xs p-1 md:text-lg font-normal ">
              {t('gml10.comparisonTable.valC2R3')}
            </div>
          </div>
          <div className=" w-1/4 text-gray-500   border  leading-none	 border-gray-300 flex-col text-center leading-none  flex items-center justify-center text-lg font-semibold ">
            <div className="h-20 w-full  text-gray-500  text-center leading-none  flex items-center justify-center  text-xs p-1 md:text-lg font-semibold ">
              {t('gml10.comparisonTable.colHeader3')}
            </div>
            <div className="h-20 w-full  text-gray-500  border-b border-t border-gray-300 flex-none  flex items-center justify-center text-lg font-semibold ">
              <XIcon className={` w-8 h-8 text-gray-500`} />
            </div>
            <div className="h-20 w-full text-gray-500  border-b border-gray-300  flex-none  flex items-center justify-center text-lg font-semibold ">
              <XIcon className={` w-8 h-8 text-gray-500`} />
            </div>
            <div className="h-20 w-full  text-gray-500   border-gray-300  flex-none  flex items-center justify-center  text-xs p-1 md:text-lg font-normal ">
              {t('gml10.comparisonTable.valC3R3')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonTable;
