/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {Fragment, useState, useEffect} from 'react';
import {getUA} from 'react-device-detect';
import * as yup from 'yup';
import {Listbox} from '@headlessui/react';
import {useTranslation} from 'react-i18next';
import {CheckIcon, SelectorIcon} from '@heroicons/react/solid';
import InputMask from 'react-input-mask';

import classNames from 'classnames';
import {usePageContext} from '../../../context/pageContext';
import {useConfigContext} from '../../../context/configContext';
import './style.css';

import {
  CaminoAllowedStates,
  PartnerAllowedStates,
} from '../../../utils/contextualDataUtils';
import {genAnonId} from '../../../utils/analyticsUtils';

const phoneRegExp = /^\(?([0-9]{3})\)?[-.● ]?([0-9]{3})[-.●]?([0-9]{4})$/;

const BasicForm = (props) => {
  // eslint-disable-next-line no-restricted-globals
  // eslint-disable-next-line react/prop-types
  const {
    queryParams,
    appendQueryString,
    isDark,
    hasTitle,
    justBiz,
    pageId,
    navToNewApp,
    location,
  } = props;
  const {t} = useTranslation();

  const schema = yup.object().shape({
    firstName: yup.string().required(`${t('gml10.form.firstnameError')}`),
    lastName: yup.string().required(`${t('gml10.form.lastnameError')}`),
    phone: yup
      .string()
      .required(`${t('gml10.form.phoneError')}`)
      .matches(phoneRegExp, `${t('gml10.form.phoneFormatError')}`),
    email: yup
      .string()
      .required(`${t('gml10.form.emailError')}`)
      .email(`${t('gml10.form.emailFormatError')}`),
    loanType: yup.string().required(`${t('gml10.form.loantypeError')}`),
    consent: yup.bool().oneOf([true], `${t('gml10.form.consentError')}`),
  });

  const {lang} = usePageContext();
  const {nonce} = useConfigContext();
  const spanish = navToNewApp ? 'es' : 'es';
  const english = navToNewApp ? 'en' : 'en';
  const payloadLang = lang === 'es' ? spanish : english;
  const {NODE_ENV} = process.env;
  const loanTypes = [
    {name: t('gml10.form.business'), value: 'business'},
    {name: t('gml10.form.personal'), value: 'personal'},
  ];

  const timeInBizOps = [
    {name: t('gml10.form.timeOpt1'), value: 'Less than 9 Months'},
    {name: t('gml10.form.timeOpt2'), value: 'Between 9 and 12 months'},
    {name: t('gml10.form.timeOpt3'), value: 'Between 1 and 2 years'},
    {name: t('gml10.form.timeOpt4'), value: 'Between 2 and 3 years'},
    {name: t('gml10.form.timeOpt5'), value: '4 or more Years'},
  ];

  const [userDetails, setUserDetails] = useState();
  const [userAgent, setUserAgent] = useState();
  const [firstName, setFirstName] = useState();
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState();
  const [lastNameError, setLastNameError] = useState('');
  const [phone, setPhone] = useState();
  const [phoneError, setPhoneError] = useState('');
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState('');
  const [loanType, setLoanType] = useState(justBiz ? 'business' : undefined);
  const [selectedLoanType, setSelectedLoanType] = useState();
  const [loanTypeError, setLoanTypeError] = useState('');
  const [consent, setConsent] = useState(true);
  const [consentError, setConsentError] = useState('');
  const [timeInBiz, setTimeInBiz] = useState('');
  const [selectedTimeInBiz, setSelectedTimeInBiz] = useState();
  const [isSubmiting, setIsSubmiting] = useState(false);
  // const [wpNonce, setWpNonce] = useState();

  const errors = {
    firstNameError: (value) => setFirstNameError(value),
    lastNameError: (value) => setLastNameError(value),
    phoneError: (value) => setPhoneError(value),
    emailError: (value) => setEmailError(value),
    loanTypeError: (value) => setLoanTypeError(value),
    consentError: (value) => setConsentError(value),
  };

  const changeLoanType = (event) => {
    setLoanTypeError('');
    setSelectedLoanType(event);
    setLoanType(event.value);
  };

  const changeTimeInBiz = (event) => {
    setSelectedTimeInBiz(event);
    setTimeInBiz(event.value);
  };

  const selectClass = (placeholder) =>
    classNames(
      'relative w-full py-2 pl-4 pr-10 text-left bg-white border rounded-lg  cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-md',
      {
        'text-gray-900': placeholder,
        'text-gray-500': !placeholder,
      },
    );

  const getUserGeolocationDetails = () => {
    // eslint-disable-next-line no-undef
    fetch(`${process.env.GATSBY_FUNCTIONS_URL}/GetUserContext`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        setUserDetails(data);
      });
  };

  useEffect(() => {
    if (NODE_ENV !== 'development') {
      setUserAgent(getUA);
      getUserGeolocationDetails();
    }
  }, []);

  const sendApplication = () => {
    setIsSubmiting(true);
    const formLead = {
      firstName,
      lastName,
      phone,
      email,
      loanType,
      consent,
    };

    const formPayload = {
      action: 'my_add_lead',
      is_agree_receive_message: true,
      fname: firstName,
      lname: lastName,
      phone,
      email,
      loan_type: loanType,
      year_in_business: timeInBiz,
      checkbox: 'on',
      lead_geo: userDetails?.region_code,
      ip_address: userDetails?.ip,
      user_agent: userAgent,
      lang: payloadLang,
      lead_device: 'Desktop',
      name_of_nonce_field: nonce,
      _wp_http_referer: '',
      anonymous_id: genAnonId(),
      cur_ur: location?.href,
      context_page_url: location?.href,
      leadform_id: 'JAM',
      ...queryParams,
    };

    schema
      .validate(formLead, {abortEarly: false, recursive: true})
      .then(() => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(formPayload)) {
          formData.append(key, value);
        }

        fetch(`${process.env.GATSBY_CAMINO_URL}/wp-admin/admin-ajax.php`, {
          method: 'POST',
          body: formData,
        })
          .then((res) => {
            const oportunUrl =
              lang === 'es'
                ? 'http://loan.oportun.com/CAMINO'
                : 'http://loan.oportun.com/CAMINO2';
            const noServiceUrl =
              lang === 'es'
                ? `${process.env.GATSBY_CAMINO_URL}/es/no_service_area`
                : `${process.env.GATSBY_CAMINO_URL}/no_service_area`;
            // eslint-disable-next-line react/prop-types
            const qstring = appendQueryString.replace('?', '&');
            const onlineAppUrl = `${
              navToNewApp
                ? process.env.GATSBY_NEW_APPLICATION_CAMINO_URL
                : process.env.GATSBY_NEW_APPLICATION_CAMINO_URL
            }?lang=${payloadLang}&first_name=${firstName}&last_name=${lastName}&email=${email}&contact_number=${phone}&leadform_id=${pageId}${qstring}`;
            const personalLoanRedirect = PartnerAllowedStates.includes(
              userDetails?.region_code,
            )
              ? oportunUrl
              : noServiceUrl;
            const businessLoanRedirect = CaminoAllowedStates.includes(
              userDetails?.region_code,
            )
              ? onlineAppUrl
              : noServiceUrl;

            window.dataLayer = window.dataLayer || [];

            const event = loanType === 'personal' ? 'pl_lead' : 'bl_lead';
            window.dataLayer.push({
              event,
            });

            window.location.href =
              loanType === 'personal'
                ? personalLoanRedirect
                : businessLoanRedirect;

            return res.json();
          })
          .catch(() => {
            setIsSubmiting(false);
          });
      })
      .catch((err) => {
        err.inner.forEach((e) => {
          errors[`${e?.path}Error`](e?.message);
        });
        setIsSubmiting(false);
      });
  };

  return (
    <>
      {hasTitle && (
        <div className="my-1 md:mt-4 md:mb-2">
          {loanType !== 'business' && <div className="h-1" />}
          <div className="text-2xl md:text-3xl  font-semibold text-center font-sans  text-orange py-3">
            <span className="block">{t('gml10.splitSection.title')}</span>
          </div>
          {loanType !== 'business' && <div className="h-1" />}
        </div>
      )}
      <div className="lg:mt-0 lg:col-span-4">
        <div className="sm:max-w-lg sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
          <div className="px-6 sm:px-12">
            <div>
              <form action="#" method="POST" className="space-y-4">
                <div>
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    autoComplete="firstname"
                    placeholder={t('gml10.form.firstname')}
                    required
                    value={firstName}
                    onChange={(e) => {
                      setFirstNameError('');
                      setFirstName(e.target.value);
                    }}
                    className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2  focus:border-white focus:placeholder-gray-500"
                  />
                </div>
                {firstNameError !== '' && (
                  <span
                    className="mb-2 text-sm text-red-300"
                    id="firstnameError">
                    {firstNameError}
                  </span>
                )}
                <div>
                  <input
                    type="text"
                    name="lname"
                    id="lname"
                    autoComplete="lastname"
                    placeholder={t('gml10.form.lastname')}
                    required
                    value={lastName}
                    onChange={(e) => {
                      setLastNameError('');
                      setLastName(e.target.value);
                    }}
                    className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2  focus:border-white focus:placeholder-gray-500"
                  />
                </div>
                {lastNameError !== '' && (
                  <span
                    className="mb-2 text-sm text-red-300"
                    id="lastNameError">
                    {lastNameError}
                  </span>
                )}
                <div>
                  <InputMask
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="phone"
                    mask="(999) 999-9999"
                    placeholder={t('gml10.form.phone')}
                    required
                    value={phone}
                    onChange={(e) => {
                      setPhoneError('');
                      setPhone(e.target.value);
                    }}
                    className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:border-white focus:placeholder-gray-500"
                  />
                </div>
                {phoneError !== '' && (
                  <span className="mb-2 text-sm text-red-300" id="phoneError">
                    {phoneError}
                  </span>
                )}
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="email"
                    placeholder={t('gml10.form.email')}
                    required
                    value={email}
                    onChange={(e) => {
                      setEmailError('');
                      setEmail(e.target.value);
                    }}
                    className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:border-white focus:placeholder-gray-500"
                  />
                </div>
                {emailError !== '' && (
                  <span className="mb-2 text-sm text-red-300" id="emailError">
                    {emailError}
                  </span>
                )}

                {!justBiz && (
                  <div>
                    <Listbox value={selectedLoanType} onChange={changeLoanType}>
                      <div className="relative mt-1">
                        <Listbox.Button
                          className={() => selectClass(!!selectedLoanType)}>
                          <span className="block truncate">
                            {selectedLoanType
                              ? selectedLoanType.name
                              : t('gml10.form.loantype')}
                          </span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {loanTypes.map((item) => (
                            <Listbox.Option
                              key={item.value}
                              className={({active}) =>
                                `${
                                  active
                                    ? 'text-amber-900 bg-amber-100'
                                    : 'text-gray-900'
                                }
                          cursor-default select-none relative py-2 pl-10 pr-4`
                              }
                              value={item}>
                              {({selected, active}) => (
                                <>
                                  <span
                                    className={`${
                                      selected ? 'font-medium' : 'font-normal'
                                    } block truncate`}>
                                    {item.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`${
                                        active
                                          ? 'text-amber-600'
                                          : 'text-amber-600'
                                      }
                                absolute inset-y-0 left-0 flex items-center pl-3`}>
                                      <CheckIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </div>
                    </Listbox>

                    {loanTypeError !== '' && (
                      <span
                        className="mb-2 text-sm text-red-300"
                        id="loanTypeError">
                        {loanTypeError}
                      </span>
                    )}
                    {loanType !== 'business' && <div className="h-1" />}
                  </div>
                )}

                {!justBiz && loanType === 'business' && (
                  <div>
                    <Listbox
                      value={selectedTimeInBiz}
                      onChange={changeTimeInBiz}>
                      <div className="relative mt-1">
                        <Listbox.Button
                          className={() => selectClass(!!selectedTimeInBiz)}>
                          <span className="block truncate">
                            {selectedTimeInBiz
                              ? selectedTimeInBiz.name
                              : t('gml10.form.time')}
                          </span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {timeInBizOps.map((item) => (
                            <Listbox.Option
                              key={item.value}
                              className={({active}) =>
                                `${
                                  active
                                    ? 'text-amber-900 bg-amber-100'
                                    : 'text-gray-900'
                                }
              cursor-default select-none relative py-2 pl-10 pr-4`
                              }
                              value={item}>
                              {({selected, active}) => (
                                <>
                                  <span
                                    className={`${
                                      selected ? 'font-medium' : 'font-normal'
                                    } block truncate`}>
                                    {item.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`${
                                        active
                                          ? 'text-amber-600'
                                          : 'text-amber-600'
                                      }
                absolute inset-y-0 left-0 flex items-center pl-3`}>
                                      <CheckIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </div>
                    </Listbox>
                  </div>
                )}

                <div className="sm:px-2">
                  <span
                    className={classNames('text-xl leading-none ', {
                      'text-white': isDark,
                      'text-gray-500': !isDark,
                    })}
                    style={{fontSize: 10, lineHeight: 1.2}}>
                    <div
                      className="lead_form__checkbox"
                      onClick={() => {
                        setConsent((val) => !val);
                        setConsentError('');
                      }}>
                      <input
                        type="checkbox"
                        checked={consent}
                        name="checkbox"
                        id="checkbox"
                        readOnly
                      />
                      <label>
                        {t('gml10.form.consent1')}
                        <a className="font-semibold" href="tel:8008520655">
                          {t('gml10.form.consent2')}
                        </a>
                        {t('gml10.form.consent3')}
                        <a
                          className="font-semibold"
                          href={`${process.env.GATSBY_CAMINO_URL}/terms-of-use/`}>
                          {t('gml10.form.consent4')}
                        </a>{' '}
                        {t('gml10.form.consent5')}
                        <a
                          className="font-semibold"
                          href={`${process.env.GATSBY_CAMINO_URL}/privacy-policy/`}>
                          {t('gml10.form.consent6')}
                        </a>
                        {t('gml10.form.consent7')}
                      </label>
                    </div>
                  </span>
                  {consentError !== '' && (
                    <span
                      className="mb-2 text-sm text-red-300"
                      id="consentError">
                      {consentError}
                    </span>
                  )}
                  {loanType !== 'business' && <div className="h-1" />}
                </div>
                <div className="pb-6">
                  <button
                    onClick={() => sendApplication()}
                    type="button"
                    disabled={isSubmiting}
                    className="w-full flex justify-center py-3 px-4  border-transparent rounded-md shadow-sm text-xl text-white bg-orange-600 hover:bg-orange-400 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 tracking-widest">
                    {isSubmiting && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24">
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    )}
                    {t('gml10.form.start_app')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicForm;
