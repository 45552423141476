import React from 'react';
import {useTranslation} from 'react-i18next';
// import sideImg from '../../../assets/images/layout/prequalify.webp';
// import {BasicForm, PaymentsCalculatorGML12} from '../../../molecules';
import PaymentsCalculator from '../../../molecules/gml12/paymentsCalculator';

import './style.css';

// eslint-disable-next-line react/prop-types
const StepsBox = ({gotoForm}) => {
  const {t} = useTranslation();
  // eslint-disable-next-line react/prop-types
  // const {queryParams, appendQueryString} = props;
  return (
    <div id="applynow" className="calculatorBox">
      <div className="py-8 overflow-hidden lg:py-10">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="relative mt-6 mb-12">
            <h2 className="text-center text-4xl font-extrabold text-white sm:text-4xl">
              {t('gml10.calculator.title')}
            </h2>
          </div>
          <PaymentsCalculator gotoForm={() => gotoForm()} />
        </div>
      </div>
    </div>
  );
};

export default StepsBox;
