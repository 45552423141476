/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {Disclosure, Menu, Popover, Transition} from '@headlessui/react';
import {MenuIcon, XIcon} from '@heroicons/react/outline';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import React, {Fragment, useEffect, useState} from 'react';
import {AnchorLink} from 'gatsby-plugin-anchor-links';

import logoSticky from '../../../assets/images/layout/logo-spn-color.svg';
import logoStickyWhite from '../../../assets/images/layout/logo-spn-white.svg';
import {usePageContext} from '../../../context/pageContext';
import './style.css';

export default function TopMenu(props) {
  // eslint-disable-next-line react/prop-types
  const {navToNewApp} = props;
  const {t} = useTranslation();
  // eslint-disable-next-line react/prop-types

  const queryString = typeof window !== 'undefined' && window.location.search;
  const getQueryString = queryString.toString();
  const [appendQueryString, setAppendQueryString] = useState();

  const {lang, originalPath} = usePageContext();
  const [isSticky, setIsSticky] = useState(false);
  const [showMenu1, setShowMenu1] = useState(false);
  const [showMenu2, setShowMenu2] = useState(false);

  const spanish = navToNewApp ? 'es' : 'es';
  const english = navToNewApp ? 'en' : 'en';
  const payloadLang = lang === 'es' ? spanish : english;

  useEffect(() => {
    const header = document.getElementById('CaminoHeader');
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener('scroll', () => {
      // eslint-disable-next-line no-restricted-globals
      if (window.pageYOffset > sticky) {
        header.classList.add('sticky');
        setIsSticky(true);
      } else {
        header.classList.remove('sticky');
        setIsSticky(false);
      }
    });
    return () => {
      window.removeEventListener('scroll', scrollCallBack);
    };
  }, []);

  useEffect(() => {
    setAppendQueryString(getQueryString);
  }, [getQueryString]);

  const linkClass = classNames(
    'inline-flex justify-center mt-6 px-1 pt-1 text-sm  focus:outline-none',
    {
      'text-gray-500': isSticky,
      'text-white': !isSticky,
    },
  );

  const linkClass2 = classNames(
    'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
    {
      'text-gray-500': isSticky,
      'text-white': !isSticky,
    },
  );

  const langToggleClass = classNames(
    'ml-2 inline-flex items-center px-1 pt-1 text-xs font-medium',
    {
      'text-gray-500': isSticky,
      'text-white': !isSticky,
    },
  );

  const whiteBtnClass = classNames(
    'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-xs  bg-transparent hover:bg-orange-400 hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500',
    {
      'text-gray-500': isSticky,
      'text-white': !isSticky,
    },
  );

  const covid = [
    {
      name: t('gml10.topNav.link1nav.link1.label'),
      href: `${t('gml10.topNav.link1nav.link1.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link1nav.link2.label'),
      href: `${t('gml10.topNav.link1nav.link2.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link1nav.link3.label'),
      href: `${t('gml10.topNav.link1nav.link3.url')}${appendQueryString}`,
    },
  ];

  const resources = [
    {
      name: t('gml10.topNav.link3nav.link1.label'),
      href: `${t('gml10.topNav.link3nav.link1.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link3nav.link2.label'),
      href: `${t('gml10.topNav.link3nav.link2.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link3nav.link3.label'),
      href: `${t('gml10.topNav.link3nav.link3.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link3nav.link4.label'),
      href: `${t('gml10.topNav.link3nav.link4.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link3nav.link5.label'),
      href: `${t('gml10.topNav.link3nav.link5.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link3nav.link6.label'),
      href: `${t('gml10.topNav.link3nav.link6.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link3nav.link7.label'),
      href: `${t('gml10.topNav.link3nav.link7.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link3nav.link8.label'),
      href: `${t('gml10.topNav.link3nav.link8.url')}${appendQueryString}`,
    },
  ];

  const navigation = [
    {
      name: t('gml10.topNav.link1.label'),
      href: `${t('gml10.topNav.link1.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link2.label'),
      href: `${t('gml10.topNav.link3nav.link2.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link3.label'),
      href: `${t('gml10.topNav.link3nav.link3.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link4.label'),
      href: `${t('gml10.topNav.link3nav.link4.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link5.label'),
      href: `${t('gml10.topNav.link3nav.link5.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link6.label'),
      href: `${t('gml10.topNav.link3nav.link6.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link8.label'),
      href: `${t('gml10.topNav.link3nav.link7.url')}${appendQueryString}`,
    },
    {
      name: t('gml10.topNav.link8.label'),
      href: `/${
        lang === 'es' ? 'en' : 'es'
      }${originalPath}${appendQueryString}`,
    },
  ];

  return (
    <Disclosure id="CaminoHeader" as="nav" className="sticky_header">
      {({open}) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center lg:mr-12">
                  <a
                    href={`${process.env.GATSBY_CAMINO_URL}/${lang}/${appendQueryString}`}>
                    <img
                      className="block lg:hidden h-8 w-auto"
                      src={isSticky ? logoSticky : logoStickyWhite}
                      alt="Workflow"
                    />
                    <img
                      className="hidden lg:block h-8 w-auto"
                      src={isSticky ? logoSticky : logoStickyWhite}
                      alt="Workflow"
                    />
                  </a>
                </div>
                <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                  {/* Current: "border-orange-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}

                  <Popover
                    className="relative"
                    onMouseOver={() => setShowMenu1(true)}
                    onMouseLeave={() => setShowMenu1(false)}>
                    {() => (
                      <>
                        <Popover.Button className={linkClass}>
                          <a
                            href={`${process.env.GATSBY_CAMINO_URL}/${lang}/covid-19/?${appendQueryString}`}>
                            {t('gml10.topNav.link1.label')}
                          </a>
                        </Popover.Button>

                        <Transition
                          show={showMenu1}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1">
                          <Popover.Panel
                            static
                            className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div className="arrow-divider-small" />
                              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                {covid.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className="-m-3 p-3 block rounded-md hover:bg-indigo-50">
                                    <p className="text-base font-medium text-indigo-600">
                                      {item.name}
                                    </p>
                                  </a>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                  <a
                    href={`${process.env.GATSBY_CAMINO_URL}/${lang}/small-business-loans/${appendQueryString}`}
                    className={linkClass}>
                    {t('gml10.topNav.link2.label')}
                  </a>

                  <Popover
                    className="relative"
                    onMouseOver={() => setShowMenu2(true)}
                    onMouseLeave={() => setShowMenu2(false)}>
                    {() => (
                      <>
                        <Popover.Button className={linkClass}>
                          <a
                            href={`${process.env.GATSBY_CAMINO_URL}/blog/${appendQueryString}`}>
                            {t('gml10.topNav.link3.label')}
                          </a>
                        </Popover.Button>

                        <Transition
                          show={showMenu2}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1">
                          <Popover.Panel
                            static
                            className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div className="arrow-divider-small" />
                              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                {resources.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className="-m-3 p-3 block rounded-md hover:bg-indigo-50">
                                    <p className="text-base font-medium text-indigo-600">
                                      {item.name}
                                    </p>
                                  </a>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                  <a
                    href={`${process.env.GATSBY_CAMINO_URL}/${lang}/success-stories/${appendQueryString}`}
                    className={linkClass}>
                    {t('gml10.topNav.link4.label')}
                  </a>
                  <a
                    href={`${process.env.GATSBY_CAMINO_URL}/${lang}/about-us/${appendQueryString}`}
                    className={linkClass}>
                    {t('gml10.topNav.link5.label')}
                  </a>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <div>
                  <a
                    href={`${t('gml10.topNav.link6.url')}${appendQueryString}`}
                    type="button"
                    className={whiteBtnClass}>
                    {t('gml10.topNav.link6.label')}
                  </a>
                </div>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  {() => (
                    <>
                      <div>
                        <a
                          href={`${
                            navToNewApp
                              ? process.env.GATSBY_NEW_APPLICATION_CAMINO_URL
                              : process.env.GATSBY_NEW_APPLICATION_CAMINO_URL
                          }${appendQueryString}${
                            appendQueryString !== '' ? '&' : '?'
                          }lang=${payloadLang}`}
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-orange-600 hover:bg-orange-400">
                          {t('gml10.topNav.link7.label')}
                        </a>
                      </div>
                    </>
                  )}
                </Menu>

                <a href={navigation[7].href} className={langToggleClass}>
                  {navigation[7].name}
                </a>
              </div>
              <div className="-mr-2 flex items-center md:hidden">
                {/* Mobile menu button */}
                <a href={navigation[7].href} className={langToggleClass}>
                  {navigation[7].name}
                </a>
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="xs:hidden bg-white">
            <div className="pt-2 pb-3 space-y-1">
              <a href={navigation[7].href} className={linkClass2}>
                {navigation[7].name}
              </a>

              <div className="pt-4 pb-3 border-t border-gray-200" />

              <a
                href={`${process.env.GATSBY_CAMINO_URL}/${lang}/covid-19/?${appendQueryString}`}
                className={linkClass2}>
                {t('gml10.topNav.link1.label')}
              </a>
              <a
                href={`${process.env.GATSBY_CAMINO_URL}/${lang}/small-business-loans/?${appendQueryString}`}
                className={linkClass2}>
                {t('gml10.topNav.link2.label')}
              </a>
              <a
                href={`${process.env.GATSBY_CAMINO_URL}/blog/?${appendQueryString}`}
                className={linkClass2}>
                {t('gml10.topNav.link3.label')}
              </a>
              <a
                href={`${process.env.GATSBY_CAMINO_URL}/${lang}/success-stories/?${appendQueryString}`}
                className={linkClass2}>
                {t('gml10.topNav.link4.label')}
              </a>
              <a
                href={`${process.env.GATSBY_CAMINO_URL}/${lang}/about-us/?${appendQueryString}`}
                className={linkClass2}>
                {t('gml10.topNav.link5.label')}
              </a>
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="mt-3 space-y-1 px-3">
                <AnchorLink
                  to={`/${lang}${originalPath}#applynow`}
                  type="button"
                  className="w-full flex justify-center py-2 px-4  border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-orange-600 hover:bg-orange-400">
                  {t('gml10.topNav.link7.label')}
                </AnchorLink>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
