import React from 'react';
import {useTranslation} from 'react-i18next';
import step1 from '../../../assets/images/layout/steps-b_1.png';
import step2 from '../../../assets/images/layout/steps-b_2.png';
import step3 from '../../../assets/images/layout/steps-b_3.png';
import './style.css';

const StepsBox = () => {
  const {t} = useTranslation();

  return (
    <div className="bg-white pt-6 sm:pt-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="w-full mb-12 border-t border-gray-300" />

        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-500 sm:text-4xl">
            {`${t('gml10.requirements.title1')} `}
            <span className="text-orange">
              {' '}
              {`${t('gml10.requirements.title2')} `}
            </span>
            {`${t('gml10.requirements.title3')} `}
          </h2>
        </div>
      </div>
      <div className="mt-10 pb-12 bg-white sm:pb-16">
        <div className="relative">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className="sm:grid sm:grid-cols-3">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 text-2xl font-extrabold text-orange">
                    {t('gml10.requirements.subtitle1')}
                  </dt>
                  <dd className="order-1 mt-2 text-lg leading-6 font-medium text-gray-500">
                    {t('gml10.requirements.description1')}
                  </dd>
                  <img className="stepimage" src={step1} alt="step1" />
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 text-2xl font-extrabold text-orange">
                    {t('gml10.requirements.subtitle2')}
                  </dt>
                  <dd className="order-1 mt-2 text-lg leading-6 font-medium text-gray-500">
                    {t('gml10.requirements.description2')}
                  </dd>
                  <img className="stepimage" src={step2} alt="step2" />
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 text-2xl font-extrabold text-orange">
                    {t('gml10.requirements.subtitle3')}
                  </dt>
                  <dd className="order-1 mt-2 text-lg leading-6 font-medium text-gray-500">
                    {t('gml10.requirements.description3')}
                  </dd>

                  <img className="stepimage" src={step3} alt="step3" />
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsBox;
