import React from 'react';
import {useTranslation} from 'react-i18next';
import './style.css';

const StatsBox = () => {
  const {t} = useTranslation();

  return (
    <div className="bg-white">
      <div className="divider-container">
        <div className="arrow-divider" />
      </div>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-12 sm:px-6 lg:px-8 lg:py-12">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-500 sm:text-4xl">
            {`${t('gml10.stats.title1')} `}
            <span className="text-orange">{t('gml10.stats.title2')}</span>
            {` ${t('gml10.stats.title3')}`}
          </h2>
        </div>
        <dl className="mt-10 text-center sm:max-w-7xl sm:mx-auto sm:grid sm:grid-cols-4 sm:gap-8">
          <div className="flex flex-col">
            <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
              {t('gml10.stats.description1')}
            </dt>
            <dd className="order-1 text-5xl font-extrabold text-orange">
              {t('gml10.stats.number1')}
            </dd>
          </div>
          <div className="flex flex-col mt-10 sm:mt-0">
            <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
              {t('gml10.stats.description2')}
            </dt>
            <dd className="order-1 text-5xl font-extrabold text-orange">
              {t('gml10.stats.number2')}
            </dd>
          </div>

          <div className="flex flex-col mt-10 sm:mt-0">
            <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
              {t('gml10.stats.description3')}
            </dt>
            <dd className="order-1 text-5xl font-extrabold text-orange">
              {t('gml10.stats.number3')}
            </dd>
          </div>
          <div className="flex flex-col mt-10 sm:mt-0">
            <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
              {t('gml10.stats.description4')}
            </dt>
            <dd className="order-1 text-5xl font-extrabold text-orange">
              {t('gml10.stats.number4')}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default StatsBox;
