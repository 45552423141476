import React from 'react';
import Helmet from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {usePageContext} from '../../../context/pageContext';

// eslint-disable-next-line react/prop-types
const SEO = () => {
  const {t} = useTranslation();
  const {lang} = usePageContext();

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={`${t('siteMetadata.title')}`}
        titleTemplate={`%s | ${t('siteMetadata.titleTemplate')}`}
      />

      <Helmet>
        <script async src="https://www.google-analytics.com/analytics.js" />
        <script>
          {` window.ga=window.ga||function()
          {(ga.q = ga.q || []).push(arguments)}
          ;ga.l=+new Date; 

            ga('create', '${process.env.GATSBY_GOOGLE_ANALYTICS_ID}', 'auto');
            ga('require', 'linker');
            ga('require', 'rd_parallel_tracking');

            // Reflective Data Parallel Tracking plugin code
            !function(){var t=window[window.GoogleAnalyticsObject||"ga"];t&&t("provide","rd_parallel_tracking",function(t){var e=t.get("sendHitTask"),n=function(){function e(t){var e=!1;try{document.createElement("img").src=n(!0)+"?"+t,e=!0}catch(t){}return e}function n(e){var n="https://us-central1-reflective-data.cloudfunctions.net/camino-rd-clickstream-tracker";return e||(n+="?tid="+encodeURIComponent(t.get("trackingId"))),n}return{send:function(t){var a;if(!(a=2036>=t.length&&e(t))){a=!1;try{a=navigator.sendBeacon&&navigator.sendBeacon(n(),t)}catch(t){}}if(!a){var r;a=!1;try{window.XMLHttpRequest&&"withCredentials"in(r=new XMLHttpRequest)&&(r.open("POST",n(),!0),r.setRequestHeader("Content-Type","text/plain"),r.send(t),a=!0)}catch(t){}}return a||e(t)}}}();t.set("sendHitTask",function(t){try{e(t)}catch(t){}n.send(t.get("hitPayload"))})})}();
          `}
        </script>
      </Helmet>
      <Helmet>
        <script>
          {`!(function () {
          var analytics = (window.analytics = window.analytics || []);
          if (!analytics.initialize)
            if (analytics.invoked)
              window.console &&
                console.error &&
                console.error('Segment snippet included twice.');
            else {
              analytics.invoked = !0;
              analytics.methods = [
                'trackSubmit',
                'trackClick',
                'trackLink',
                'trackForm',
                'pageview',
                'identify',
                'reset',
                'group',
                'track',
                'ready',
                'alias',
                'debug',
                'page',
                'once',
                'off',
                'on',
                'addSourceMiddleware',
                'addIntegrationMiddleware',
                'setAnonymousId',
                'addDestinationMiddleware',
              ];
              analytics.factory = function (e) {
                return function () {
                  var t = Array.prototype.slice.call(arguments);
                  t.unshift(e);
                  analytics.push(t);
                  return analytics;
                };
              };
              for (var e = 0; e < analytics.methods.length; e++) {
                var key = analytics.methods[e];
                analytics[key] = analytics.factory(key);
              }
              analytics.load = function (key, e) {
                var t = document.createElement('script');
                t.type = 'text/javascript';
                t.async = !0;
                t.src =
                  'https://cdn.segment.com/analytics.js/v1/' +
                  key +
                  '/analytics.min.js';
                var n = document.getElementsByTagName('script')[0];
                n.parentNode.insertBefore(t, n);
                analytics._loadOptions = e;
              };
              analytics._writeKey = '${process.env.GATSBY_SEGMENT_KEY}}';
              analytics.SNIPPET_VERSION = '4.13.2';
              analytics.load('${process.env.GATSBY_SEGMENT_KEY}', { integrations: {'Intercom': false } });
              analytics.page();
            }
        })()`}
        </script>
      </Helmet>
      {/* <Helmet>
        <script>
          {`(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2494601,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
      </Helmet> */}
    </>
  );
};

export default SEO;
