/* eslint-disable react/no-array-index-key */
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Disclosure} from '../../../atoms/common';
import {usePageContext} from '../../../context/pageContext';

export default function Faq() {
  const {t} = useTranslation();
  const {lang} = usePageContext();
  const faqArray =
    lang === 'en'
      ? [
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500">
                {t('gml10.faq.question1.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <ol>
                  <li>
                    <div className="mb-1 font-semibold ">
                      1. We Want to Know More About You and Your Small Business
                    </div>
                    Complete our short online application: it only has a few
                    simple questions about your small business. You will learn
                    if you prequalify for a business loan in just a few seconds!
                  </li>
                  <li>
                    <div className="mb-1 font-semibold ">
                      2. Review Your Options With A Small Business Loan
                      Specialist
                    </div>
                    We will explain your funding options to ensure you have the
                    capital you need without hurting your cash flow. At this
                    point, we will answer any question you may have about the
                    loan process.
                  </li>
                  <li>
                    <div className="mb-1 font-semibold ">
                      3. Submit Basic Financial Records
                    </div>
                    Submit information about your business cash flow. Sharing
                    your financial statements is easy and secure! This will help
                    us personalize your business loan so that it fits all your
                    needs.
                  </li>
                  <li>
                    <div className="mb-1 font-semibold ">
                      4. Accept the Final Offer and Business Loan Terms
                    </div>
                    Our team is ready to present a final loan offer. You just
                    need to review the terms and sign!
                  </li>
                  <li>
                    <div className="mb-1 font-semibold ">
                      5. Receive Funding
                    </div>
                    You’re ready to receive the capital you need! We’ll deposit
                    the funds directly into your bank account.
                  </li>
                </ol>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question2.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p className="mb-1">
                  Do you want to apply for a business loan with Camino
                  Financial? These are the requirements you must meet:
                </p>
                <ol>
                  <li>
                    1. Your business needs to have been operating for at least 9
                    months
                  </li>
                  <li>
                    2. Earn $2,500 in monthly gross income (or $30,000 annually)
                  </li>
                  <li>
                    3. Have an SSN (Social Security Number) or an ITIN
                    (Individual Taxpayer Identification Number)
                  </li>
                  <li>
                    4. You need to be the owner of at least 50% of the business
                  </li>
                  <li>
                    5. Be located in the US (except New Jersey, Hawaii, and
                    Puerto Rico)
                  </li>
                  <li>6. Don’t have any bankruptcies in the last 24 months</li>
                  <li>
                    7. You should be current with other outstanding credit
                    obligations
                  </li>
                </ol>
                <p className="mt-4">
                  Continue reading{' '}
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/why-camino-financial-has-fewer-business-loan-requirements-than-other-lenders/`}>
                    Why Camino Financial Has Fewer Business Loan Requirements
                    Than Other Lenders?
                  </a>
                </p>
              </div>
            ),
          },
          {
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question3.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>
                  We offer{' '}
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/secured-vs-unsecured-business-loans/`}>
                    unsecured
                  </a>
                  , term loans at fixed interest rates and with flexible
                  requirements. We’ll help you finance the needs of your
                  company.
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question4.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>
                  Yes! We understand the ins and outs of the industry and know
                  what you need. Our experience with business owners and
                  contractors like you helps us create loans that will fulfill
                  all your needs.
                </p>
                <p>
                  We don’t think of our customers like numbers. You are part of
                  our family.
                </p>
                <p>
                  Learn{' '}
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/why-camino-financial-customers-are-happy-to-work-with-us/`}>
                    why Camino Financial customers are happy to work with us
                  </a>
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question5.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>Of course!</p>
                <p>
                  We understand how important funding is for all businesses;
                  that’s why we don’t factor in your immigration status. When
                  applying for a loan, we focus on you, your business, and your
                  story.
                </p>
                <p>At Camino Financial, we approve owners with only an ITIN.</p>
                <p className="mt-2">
                  Continue reading{' '}
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/myth-or-truth-i-cannot-get-credit-if-i-only-have-a-tax-id/`}>
                    Can I Get Credit Only with an ITIN?
                  </a>
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question6.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>Yes, you can apply!</p>
                <p>
                  We don’t require you to have previous credit history nor a
                  minimum credit score. Why? Because knowing that you are a
                  hard-worker business owner is more important than a score.
                  Everyone should have the same access to financing.
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question7.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>No.</p>
                <p>There are 2 ways to review your credit history:</p>
                <ol>
                  <li>
                    1. A “hard pull,” that becomes part of your record and
                    affects your score. This is what many lenders use.
                  </li>
                  <li>
                    2. A “
                    <a
                      className="text-orange"
                      href={`${process.env.GATSBY_CAMINO_URL}/credit-check-hard-pull-vs-soft-pull/`}>
                      soft pull
                    </a>
                    ,” which doesn’t affect your credit score. This is what we
                    do at Camino Financial to help you maintain a healthy score.
                  </li>
                </ol>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question8.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>With us, you don’t need to put up collateral.</p>
                <p>
                  We offer unsecured loans, which means{' '}
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/why-camino-financial-doesnt-require-collateral-for-their-business-loans/`}>
                    we don’t ask for collateral
                  </a>{' '}
                  to secure your loan. We don’t want you to risk your property,
                  so your vehicles, equipment, and other assets are safe with
                  us.
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question9.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>
                  Our annual interest rates range from 12.00% to 24.75% for our
                  business loans and 19.0% to 34.0% for our microloans.
                </p>
                <p>
                  Learn{' '}
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/how-does-camino-financial-calculate-your-loan-interest-rate/`}>
                    how we calculate your interest rate.
                  </a>
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question10.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>Yes.</p>
                <p>
                  We know how important it is that personal information stays
                  safe. So we use{' '}
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/how-does-camino-financial-use-bank-technology/`}>
                    the same technology that banks use
                  </a>{' '}
                  to protect sensitive information and documents.
                </p>
                <p>With us, your information will always remain safe.</p>
              </div>
            ),
          },
        ]
      : [
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500">
                {t('gml10.faq.question1.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <ol>
                  <li>
                    <div className="mb-1 font-semibold ">
                      1. Queremos conocerte a ti y a tu empresa
                    </div>
                    Llena nuestra breve aplicación en línea: son solo unas pocas
                    preguntas sobre tu empresa. ¡Sabrás si precalificas para un
                    préstamo para negocio en tan solo unos segundos!
                  </li>
                  <li>
                    <div className="mb-1 font-semibold ">
                      2. Un representante de cuenta te presentará tus opciones
                    </div>
                    Te explicaremos todas tus opciones de financiamiento para
                    asegurarnos de que tengas el capital que necesitas sin
                    afectar tu flujo de efectivo. También responderemos
                    cualquier pregunta que puedas tener sobre el proceso de
                    financiamiento.
                  </li>
                  <li>
                    <div className="mb-1 font-semibold ">
                      3. Compártenos documentos financieros básicos
                    </div>
                    Envíanos información sobre el flujo de efectivo de tu
                    negocio. ¡Compartir estos documentos es fácil y seguro! Esto
                    nos ayudará a personalizar tu préstamo para negocio y
                    ajustarlo a tus necesidades.
                  </li>
                  <li>
                    <div className="mb-1 font-semibold ">
                      4. Acepta la oferta final y los términos del préstamo
                    </div>
                    Nuestro equipo te presentará una oferta final de préstamo.
                    ¡Solo necesitas revisar los términos y firmar!
                  </li>
                  <li>
                    <div className="mb-1 font-semibold ">
                      5. Recibe tu capital
                    </div>
                    ¡Ya estás listo para recibir el capital que necesitas!
                    Depositaremos los fondos directamente en tu cuenta bancaria.
                  </li>
                </ol>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question2.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p className="mb-1">
                  Para solicitar un préstamo para negocio con Camino Financial
                  debes cumplir estos requisitos:
                </p>
                <ol>
                  <li>1. Tu negocio debe llevar operando al menos 9 meses</li>
                  <li>
                    2. Ganar $2,500 en ingresos brutos mensuales (o $30,000
                    anuales)
                  </li>
                  <li>3. Tener un ITIN o un SSN</li>
                  <li>4. Ser dueño de al menos el 50% del negocio</li>
                  <li>
                    5. Estar ubicado en los EE. UU. (excepto Nueva Jersey,
                    Hawaii y Puerto Rico)
                  </li>
                  <li>6. No tener bancarrotas en los últimos 24 meses</li>
                  <li>
                    7. Estar al día con otras obligaciones crediticias
                    pendientes
                  </li>
                </ol>
                <p className="mt-4">
                  Descubre{' '}
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/why-camino-financial-has-fewer-business-loan-requirements-than-other-lenders/`}>
                    por qué Camino Financial tiene menos requisitos que otros
                    prestamistas ?
                  </a>
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question3.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>
                  Ofrecemos préstamos{' '}
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/secured-vs-unsecured-business-loans/`}>
                    no asegurados,
                  </a>
                  que no requieren colateral. Nuestras tasas de interés son
                  fijas y los pagos mensuales. Te ayudaremos a financiar tus
                  necesidades.
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question4.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>
                  ¡Si! Nosotros entendemos cómo funciona tu industria y sabemos
                  lo que necesitas. Nuestra experiencia con contratistas como tú
                  nos permite crear préstamos que se adaptan a todas tus
                  necesidades.
                </p>
                <p>
                  No pensamos en nuestros clientes como números, tú eres parte
                  de nuestra familia.
                </p>
                <p>
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/es/por-que-nuestros-clientes-estan-felices-con-camino-financial/`}>
                    Nuestros clientes están felices con Camino Financial
                  </a>
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question5.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>¡Claro que sí!</p>
                <p>
                  Entendemos lo importante que es para todas las empresas
                  obtener capital. Por eso no nos fijamos en tu estado
                  migratorio. Al solicitar un préstamo, nos enfocamos en ti, en
                  tu negocio y en tu historia.
                </p>
                <p>
                  En Camino Financial sí aprobamos a los empresarios que solo
                  tienen ITIN.
                </p>
                <p className="mt-2">
                  Sigue leyendo:
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/es/puedo-conseguir-credito-si-nomas-tengo-una-identificacion-fiscal/`}>
                    ¿Puedo conseguir un préstamo solo con mi ITIN?
                  </a>
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question6.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>¡Sí!</p>
                <p>
                  No te pedimos que tengas historial de crédito previo ni un
                  puntaje crediticio mínimo. ¿Por qué? Porque nos enfocamos en
                  que seas un empresario trabajador y no en una puntuación.
                  Creemos que todos deben tener el mismo acceso a préstamos.
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question7.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>No.</p>
                <p>Existen 2 formas de conocer tu historial crediticio:</p>
                <ol>
                  <li>
                    1. Un “hard pull”, que se vuelve parte de tu registro y
                    afecta tu puntaje. Esto es lo que hacen otros prestamistas.
                  </li>
                  <li>
                    2. Un
                    <a
                      className="text-orange"
                      href={`${process.env.GATSBY_CAMINO_URL}//credit-check-hard-pull-vs-soft-pull/`}>
                      “soft pull“
                    </a>
                    , que no afecta tu puntaje de crédito. Esto es lo que
                    hacemos en Camino Financial para ayudarte a mantener un
                    puntaje fuerte.
                  </li>
                </ol>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question8.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>Con nosotros no necesitas colateral.</p>
                <p>
                  Ofrecemos préstamos no-asegurados, que significa para aprobar
                  tu préstamo. We offer unsecured loans, which means{' '}
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/why-camino-financial-doesnt-require-collateral-for-their-business-loans/`}>
                    que no pedimos colateral
                  </a>{' '}
                  No queremos que pongas en peligro tus bienes, por lo que tus
                  vehículos, maquinaria y otros activos están seguros en Camino
                  Financial.
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question9.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>
                  Nuestra tasa de interés anual es de entre 12.00% y 24.75% para
                  nuestros préstamos para pequeños negocios. Para nuestros
                  micropréstamos es entre 19.0% y 34.0%.
                </p>
                <p>
                  Descubre aquí{' '}
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/es/como-calcula-camino-financial-la-tasa-de-interes-de-tu-prestamo/`}>
                    cómo calculamos las tasas de interés.
                  </a>
                </p>
              </div>
            ),
          },
          {
            isOpen: false,
            question: (
              <div className="text-xl font-semibold text-gray-500 mb-0.5">
                {t('gml10.faq.question10.question')}
              </div>
            ),
            answer: (
              <div className="pl-4 pr-12">
                <p>Sí.</p>
                <p>
                  Sabemos lo importante que es mantener tu información personal
                  segura. Por eso utilizamos
                  <a
                    className="text-orange"
                    href={`${process.env.GATSBY_CAMINO_URL}/how-does-camino-financial-use-bank-technology/`}>
                    la misma tecnología que utilizan los bancos
                  </a>{' '}
                  para proteger información y documentos confidenciales.
                </p>
                <p>Con nosotros, tu información siempre estará segura.</p>
              </div>
            ),
          },
        ];

  const [faqs] = useState([...faqArray]);

  const closeAllFaqs = () => {
    // const toggleFaqs = [...faqs];
    // toggleFaqs.map((question, id) => {
    //   return {
    //     ...question,
    //     isOpen: false,
    //   };
    // });
    // setFaqs(toggleFaqs);
  };
  return (
    <>
      <div className="transition duration-500 ease-in bg-white max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="w-full px-4 py-16">
          <div className="relative">
            <h2 className="text-left text-4xl font-extrabold text-gray-500 sm:text-4xl">
              {t('gml10.faq.title')}
            </h2>
          </div>
          <div className="w-full my-8">
            {faqs.map((item, idx) => (
              <Disclosure
                // eslint-disable-next-line react/no-array-index-key
                key={`question_${idx}`}
                title={item.question}
                description={item.answer}
                callback={(open) => closeAllFaqs({open, idx})}
                defaultVal={item.isOpen}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
